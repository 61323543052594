<template>
    <div ref="legendHolder" class="legend mx-auto p-4 my-4 container text-base">
        
    </div>
</template>
<script>
import { fromHar } from "perf-cascade";

export default {
    props: {
        waterfallString:{
            type: String,
            default:null,
        },
    },
    data(){
        return {
            perfCascadeSvg: null,
            legendHolderEl: null,
            perfCascadeOptions: null,
        };
    },
    mounted() {
        this.legendHolderEl = this.$refs.legendHolder;
        this.perfCascadeOptions = {
            rowHeight: 30, //default: 23
            showAlignmentHelpers: true, //default: true
            showIndicatorIcons: true, //default: true
            leftColumnWidth: 20, //default: 25
            legendHolder: this.legendHolderEl, //default: undefined (hide-legend)
            showUserTiming: false, //default: false
            showUserTimingEndMarker: false, //default: false
        };
        if (this.legendHolderEl) {
            while (this.legendHolderEl.firstChild) {
                this.legendHolderEl.removeChild(this.legendHolderEl.firstChild);
            }
        }
        this.perfCascadeSvg = fromHar(
            this.waterfallString,
            this.perfCascadeOptions
        );
        this.legendHolderEl.appendChild(this.perfCascadeSvg);

    },
}

</script>

<style>
.type-video{
    background: #752669;
}
.resource-legend li::before{
    vertical-align: sub;
}
</style>